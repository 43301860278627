import Vue from "vue";
import titleMixin from "@/mixins/title";
export default Vue.extend({
    mixins: [titleMixin],
    data() {
        return {
            title: this.$t("account.resetPasswordRequest.title"),
            isResetPasswordSent: false,
            errorMessage: "",
            email: "",
            validationOptions: {
                rules: {
                    email: {
                        required: true,
                        email: true
                    },
                }
            }
        };
    },
    methods: {
        async sendResetPassword() {
            await this.$store.dispatch("account/sendResetPassword", {
                email: this.email,
            });
            this.isResetPasswordSent = true;
        },
        async returnToLogin() {
            return this.$router.push('/account/login');
        }
    }
});
